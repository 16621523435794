.EJA {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    color: rgb(241, 241, 241);
}
.EJA h1 {
    margin-top: 40px;
    text-align:center;
    font-size: 40px;
}
.EJA p {
    text-align:center;
    margin-bottom: 40px;
}

.EJA .Block1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}
.EJA .Block1 .image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding: 20px;
}
.EJA .Block1 .image h2{
    margin-bottom: 10px;
    color: #fcc301;
    font-size: 30px;
}
.EJA .Block1 .image img {
    width: 80%;
    border-radius:6px;
}
.EJA .Block1 .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 20px;
}


.EJA .Block2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
}
.EJA .Block2 .image {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    padding: 20px 30px;
}

.EJA .Block2 .image h2{
    margin-bottom: 10px;
    color: #fcc301;
    font-size: 30px;
}
.EJA .Block2 .image img {
    width: 80%;
    border-radius:6px;
}
.EJA .Block2 .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 20px;
}

.EJA .block3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 90%;
}

.EJA .block3 .itens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 30%;
    border-radius: 6px;
    background-color: rgb(20, 0, 40);
    margin: 10px;
    text-align: center;
}

.EJA .block3 .itens svg {
    width: 30px;
    margin-bottom: 10px;
}
.EJA .block3 .itens p {
    text-align: center;
}

.EJA .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 90%;
    margin-bottom: 20px;
}

.EJA .button a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #fff;
    color: var(--Primary);
    border-radius: 6px;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 20px;
}