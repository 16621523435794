.Search {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 40px 0;
}

.Search form {
    width: 70%;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: col;
    justify-content: center;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 15px -1px #000000; 
box-shadow: 0px 0px 5px -1px #000000;
margin-bottom: 10px;
}

.Search form button {
    width: 50px;
    height: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: none;
    color: rgb(165, 165, 165);
    font-size: 16px;
    background-color: var(--Background);
}
.Search form input {
    width: 100%;
    height: 50px;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: none;
    font-size: 16px;
    background-color: var(--Background);
    outline: none;
}
.Search form input::placeholder {
    color: rgb(165, 165, 165);
}

@media(max-width:550px){
    .Search form {
        width: 90%;
    }
}