.SliderForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    color: #fff;
    margin: 40px 0;
}
.SliderForm h3{
    text-align:center;
    color: #fff;
    margin: 10px 0;
}

.SliderForm form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    background-color: #fff;
    border-radius: 6px;
    padding:20px;
}


.SliderForm form .label-avatar{
    width: 400px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 5px 0;
}

.SliderForm form .label-avatar input {
    display: none;
}

.SliderForm form .label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
}

.SliderForm form .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
}

.SliderForm form .label-avatar img{
    margin-bottom: 1em;
    border-radius: 6px;
    object-fit: cover;
}

.SliderForm form input{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}

.SliderForm form button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    background-color: rgb(27, 0, 88);
    color: #fff;
    padding: 10px
}

.SliderForm form .double {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
}

.SliderForm form .double select{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 47%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}