.StudentArea {
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: row;
    width: 100%;
    heigth: 100%;
    padding: 100px 20px;
    background-color:var(--Background);
    flex-wrap: wrap;
}

.StudentArea .textStudent {
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: row;
    width: 100%;
    margin: 20px;
}
.StudentArea .item {
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    width: 150px;
    margin: 10px;

}

.StudentArea .textStudent h2 {
    color: var(--Primary);
    font-weight: 700;
}
.StudentArea .item a{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px 20px;
    color: var(--White);
    background-color: var(--Blue);
    border-radius: 20px;
    border: none;
    margin: 10px 0;
    text-decoration: none;
}
.StudentArea .item .image{
    width: 150px;
    height: 150px;
    object-fit: cover;
    -webkit-box-shadow: 0px 0px 15px -1px #000000; 
    box-shadow: 0px 0px 5px -1px #000000;
    border-radius: 6px;
}
.StudentArea .item .image img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 6px;

}
