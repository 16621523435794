.newCourseTecnic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    color: rgb(27, 0, 88);
}
.newCourseTecnic .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}
.newCourseTecnic .buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 6px;
    background-color: #fff;
    color: var(--Primary);
    border: none;
    margin: 40px 0 10px 0;
}