.coursesFormEditEJA {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    color: rgb(27, 0, 88);
    margin: 40px 0;
    color: rgb(27, 0, 88);
}
.coursesFormEditEJA h3{
    text-align:center;
    color: rgb(27, 0, 88);
    margin: 10px 0;
}

.coursesFormEditEJA form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    background-color: #fff;
    border-radius: 6px;
    padding:20px;
}


.coursesFormEditEJA form .label-avatar{
    width: 400px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 5px 0;
}

.coursesFormEditEJA form .label-avatar input {
    display: none;
}

.coursesFormEditEJA form .label-avatar span {
    z-index: 99;
    position: absolute;
    opacity: 0.7;
    transition: all .5s;
}

.coursesFormEditEJA form .label-avatar span:hover{
    opacity: 1;
    transform: scale(1.4);
}

.coursesFormEditEJA form .label-avatar img{
    margin-bottom: 1em;
    border-radius: 6px;
    object-fit: cover;
}

.coursesFormEditEJA form input{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}
.coursesFormEditEJA form select{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}

.coursesFormEditEJA form textarea{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px;
    resize: none;
}

.coursesFormEditEJA form button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    background-color: rgb(27, 0, 88);
    color: #fff;
    padding: 10px
}

.coursesFormEditEJA form .double {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 70%;
}

.coursesFormEditEJA form .double input{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 47%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}
.coursesFormEditEJA form .double select{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 47%;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}



