.ListCategories {
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--Primary);
    width: 100%;
}

.ListCategories h1 {
    margin: 40px;
}

.ListCategories .CategoriesUnic {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    color: var(--White);
    background-color: var(--Primary);
    width: 80%;
    border-radius: 6px;
    padding: 10px;
    margin: 10px;
}

.ListCategories .CategoriesUnic .text {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
    width: 50%;
}
.ListCategories .CategoriesUnic .text h5 {
    margin:5px;
}
.ListCategories .CategoriesUnic .button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:flex-end;
}
.ListCategories .CategoriesUnic .button button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    padding: 8px;
    margin: 5px;
    border: none;
    font-size: 12px;
}

.ListCategories .CategoriesUnic .button button:hover {
    background-color: var(--Blue);
    color: var(--White);
}
.ListCategories .CategoriesUnic .button button svg {
    font-size:16px;
}


.ListCategories .link a {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--White);
    background-color: var(--Primary);
    border-radius: 6px;
    padding: 10px;
    margin: 5px;
    border: none;
    text-decoration: none;
    font-weight: 700;
}

@media(max-width: 650px) {
    .ListCategories .CategoriesUnic {
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
    }

    .ListCategories .CategoriesUnic .text {
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content:center;
        width: 100%;
    }
}