.about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width: 100%;
    background-color:var(--Background2)
}
.about .top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction:column;
    width: 100%;
    background-image: url("../../assets/images/saquarema.jpg"); /* The image used */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    height: 400px;
}

.about .top .block {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction:column;
    width: 430px;
    background-color:var(--Primary);
    filter: opacity(0.95);
    padding: 50px;
    margin-left: 100px;
    margin-top: 100px;
    z-index: 50;
}
.about .top .block img {
    width: 50px;
}
.about .top .block h1 {
    color: var(--White);
    font-size: 40px;
    margin: 20px 0;
}
.about .top .block svg {
    color: var(--White);
    font-size: 30px;
    filter: opacity(0.4)
}

.about .image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.about .image img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.about .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background-color:var(--Background);
    width: 100%;
}
.about .text .topText {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction:row;
    background-color:var(--Background);
    width: 50%;
    margin: 150px 0 40px 0;
}
.about .text .topText .logo {
    width: 50px;
    border-right: 1px solid var(--Primary);
    margin: 0 10px;
}
.about .text .topText .logo img {
    width: 50px;
    margin-left: -10px;
}
.about .text .topText .title h1 {
    color: var(--Primary);
}
.about .text .topText .title h3 {
    color: var(--Background2);
    font-weight: 400;
}
.about .text .history {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    background-color:var(--Background);
    width: 80%;
}
.about .text .history p {
    text-align: left;
    margin: 10px 0;
    white-space: pre-line;
}


@media(max-width:650px) {
    .about .top {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        width: 100%;
        background-image: url("../../assets/images/saquarema.jpg"); /* The image used */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        height: 400px;
    }
    
    .about .top .block {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction:column;
        width: 90%;
        background-color:var(--Primary);
        filter: opacity(0.95);
        padding: 25px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 150px;
        z-index: 50;
    }
    .about .top .block img {
        width: 50px;
    }
    .about .top .block h1 {
        color: var(--White);
        font-size: 40px;
        margin: 20px 0;
    }
    .about .top .block svg {
        color: var(--White);
        font-size: 30px;
        filter: opacity(0.4)
    }
}