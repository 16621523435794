.whatsapp {
    width: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    padding: 20px;
}
.whatsapp .image {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--Background);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
}

.whatsapp .icons {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
}
.whatsapp .icons p {
    background-color: var(--Primary);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 50%;
    margin-right: 10px;
}
.whatsapp .icons p:hover {
    background-color: var(--Blue);
}
.whatsapp .icons svg {
    font-size: 20px;
   color: var(--White);
}