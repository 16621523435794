.coursesPagePage {
    background-color: var(--Background);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
}

.coursesPage .titleBar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 80px 0 0 0;
}

.coursesPage .titleBar .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.coursesPage .titleBar .title h1{
    text-align: center;
    color: var(--Primary) ;
}

.coursesPage .titleBar .title p{
    text-align: left;
    color: #ffffff;
}


.coursesPage .titleBar .selection {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 20px;
}
.coursesPage .titleBar .selection .coursesPageSelection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.coursesPage .titleBar .selection .coursesPageSelection select {
    display: none;
}
.coursesPage .titleBar .selection .coursesPageSelection button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--Blue);
    padding: 10px 20px;
    margin: 5px;
    font-weight: bold;
    border: none;
    color: #fff;
    border-radius: 20px;
}

.coursesPage .titleBar .selection .coursesPageSelection button:hover {
    box-shadow: 1px 1px 5px 1px rgba(255, 255, 255, 0.1);
    background-color: rgb(31, 0, 62);
}

.coursesPage .listcoursesPage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.coursesPage .listcoursesPage .itemscoursesPage{
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic{
    width: 450px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic h2{
    color: var(--Primary);
   }
   
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    margin: 5px 0;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc1{
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc2{
    width: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc1 h6{
 color: var(--Primary);
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc2 h6{
 color: var(--Primary);
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc1 a {
text-decoration: none;
color: var(--Background2);
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc1 p{
 font-size: 13px;
 text-decoration: none;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc2 p{
 font-size: 13px;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc1 hr{
 width: 100%;
}
.coursesPage .listcoursesPage .itemscoursesPage .itemUnic .text .bloc2 hr{
 width: 100%;
}
.coursesPage .blockFinal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top:20px;
    background-color: rgb(21, 4, 56);
}
.coursesPage .blockFinal .block1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: var(--White);
    text-align: center;
}

.coursesPage .blockFinal .block1 h1{
    color: var(--Blue);
}
.coursesPage .blockFinal .block1 h3{
    margin-top: 10px;
}
.coursesPage .blockFinal .block1 p{
    margin-top: 30px;
    text-align: center;
}
.coursesPage .blockFinal .block2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    color: var(--White);
    background-color: rgb(28, 6, 72);
}
.coursesPage .blockFinal .block2 form{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: var(--White);

}
.coursesPage .blockFinal .block2 form input{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    border: none;
    margin: 5px 0;
}
.coursesPage .blockFinal .block2 form button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    color: var(--White);
    background-color: var(--Blue);
    border-radius: 6px;
    border: none;
    margin: 5px 0;
}


@media(max-width:750px){
    .coursesPage .titleBar .selection .coursesPageSelection select {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-color: var(--Blue);
        padding: 10px 40px;
        margin: 5px;
        font-weight: bold;
        border: none;
        color: #fff;
        border-radius: 20px;
    }
    .coursesPage .titleBar .selection .coursesPageSelection button {
        display: none;
    }
}

@media(max-width:650px) {
    
.coursesPage .blockFinal{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top:20px;
    background-color: rgb(21, 4, 56);
}
.coursesPage .blockFinal .block1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    color: var(--White);
    text-align: center;
}

.coursesPage .blockFinal .block1 h1{
    color: var(--Blue);
    text-align: center;
}
.coursesPage .blockFinal .block1 h3{
    margin-top: 10px;
    text-align: center;
}
.coursesPage .blockFinal .block1 p{
    margin-top: 30px;
    text-align: center;
}
.coursesPage .blockFinal .block2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;
    color: var(--White);
    background-color: rgb(28, 6, 72);
    text-align: center;
}
.coursesPage .blockFinal .block2 form{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    color: var(--White);

}
.coursesPage .blockFinal .block2 form input{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    border: none;
    margin: 5px 0;
}
.coursesPage .blockFinal .block2 form button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 5px 10px;
    color: var(--White);
    background-color: var(--Blue);
    border-radius: 6px;
    border: none;
    margin: 5px 0;
}


@media(max-width:750px){
    .coursesPage .titleBar .selection .coursesPageSelection select {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-color: var(--Blue);
        padding: 10px 40px;
        margin: 5px;
        font-weight: bold;
        border: none;
        color: #fff;
        border-radius: 20px;
    }
    .coursesPage .titleBar .selection .coursesPageSelection button {
        display: none;
    }
}
}