.BlogPage  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--Primary);
    padding: 80px 0 0 0 ;
}
.BlogPage h1  {
    text-align: center;
    margin: 40px 0;
}

.BlogPage .Main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    color: var(--Primary);
    margin: 0 0 40px 0;
}
.BlogPage .Main .notices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--Primary);
}
.BlogPage .Main .categories{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 300px;
    height: 500px;
    color: var(--Primary);
    background-color: #ccc;
    margin-left: 40px;
    border-radius: 10px;
}

.BlogPage .Main .notices .noticesAll {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    color: #512A79;
    background-color: rgb(241, 241, 241);
    border-radius: 15px;
    margin: 10px;
}

.BlogPage .Main .notices .noticesAll .image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin: 0px auto;
}

.BlogPage .Main .notices .noticesAll .image img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.BlogPage .Main .notices .noticesAll .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
 }
.BlogPage .Main .notices .noticesAll .text h4 {
    text-align: center;
    margin: 10px 10px 15px 10px;
    text-decoration: none;
    color: var(--Primary);
}

.BlogPage .Main .notices .noticesAll .text h4 a{
    text-decoration: none;
    color: var(--Primary);
}


.BlogPage .Main .notices .noticesAll .text h5 {
    text-align: center;
    font-weight: 400;
}

.BlogPage .Main .notices .noticesAll .text p {
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
}

@media (max-width: 900px) {

    .BlogPage .Main {
        flex-wrap: wrap;
    }
    .BlogPage .Main .notices .noticesAll {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 10px;
        color: #512A79;
        background-color: rgb(241, 241, 241);
        border-radius: 15px;
        margin: 10px;
        text-align: center;
    }
    
    .BlogPage .Main .notices .noticesAll .image {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
        margin: 0px auto;
    }

    .BlogPage .Main .notices .noticesAll .image img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
    }

    @media (max-width: 800px) {

        .BlogPage  .Main {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0;
            color: rgb(241, 241, 241);
        } 

        
        .BlogPage .Main .notices .noticesAll {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 90%;
            padding: 10px;
            color: #512A79;
            background-color: rgb(241, 241, 241);
            border-radius: 15px;
            margin: 10px;
        }
        
        .BlogPage .Main .notices .noticesAll .image {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            object-fit: cover;
            margin: 0px auto;
        }
    
        .BlogPage .Main .notices .noticesAll .image img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}

