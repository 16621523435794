.PostUnic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.PostUnic .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 50px 50px 0 50px;
    background-color:var(--Background)
    
}
.PostUnic .title .text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 60px;
    color: var(--Primary);
}
.PostUnic .title .text h1 {
    font-size:40px;
    margin: 10px 0;
}
.PostUnic .title .text h3 {
    margin: 10px 0;
}

.PostUnic .title .image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    padding: 0 20px;
}
.PostUnic .title .image img {
    margin-top: 20px;
}
.PostUnic .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    background-color:#ccc
}
.PostUnic .menu .text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 70%;
}
.PostUnic .menu .text p {
    color: var(--Background2);
    margin-right: 30px;
}
.PostUnic .menu a {
    background-color: var(--Magenta);
    padding: 20px 40px;
    border-radius: 6px;
    color: var(--White);
    border:none;
    margin: 0 40px ;
    text-decoration: none;
}
.PostUnic .aboutCourse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 40px 20px;
    background-color:var(--Background)
}
.PostUnic .subscript {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.PostUnic .subscript .image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 80%;
    border-radius: 10px;
}
.PostUnic .subscript .image img {
    width: 80%;
    border-radius: 10px;
}
.PostUnic .aboutCourse .text {
    width: 70%;
    padding: 20px;
    white-space: pre-line;
}
.PostUnic .aboutCourse .text h1 {
    margin-bottom: 20px
}
.PostUnic .aboutCourse .text p {
    white-space: pre-line;
}
.PostUnic .aboutCourse .text p a {
    text-decoration: none;
    color: var(--Blue);
}
