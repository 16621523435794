.footerForm {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width:400px;
    color: #fff;
    padding: 20px;
    text-align: left;
}
.footerForm .image {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid var(--Background);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
}

.footerForm h2 {
    margin-bottom: 10px;
    }

.footerForm img{
    width: 200px;
    margin-bottom: 10px;
}

.footerForm p{
   margin-bottom: 5px;
   font-size: 12px;
}

.footerForm .icons{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    width: 65%;
    text-align: left;
    
 }

 .footerForm .icons p {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
     background-color: #fff;
     color: #451A70;
     width: 30px;
     height: 30px;
     border-radius: 4px;
     margin: 5px;

 }

 .footerForm .icons p svg {
     color: #451A70;
 }

 .footerForm .icons p:hover {
    background-color: #b36cfa;
    color: #fff;
}