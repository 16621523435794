.ListCourses {
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--Primary);
    width: 100%;
}

.ListCourses h1 {
    margin-top:  20px ;
}
.ListCourses h4 {
    margin-bottom:  20px ;
}

.ListCourses .selection {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px;
}
.ListCourses .selection .coursesPageSelection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.ListCourses .selection .coursesPageSelection select {
    display: none;
}
.ListCourses .selection .coursesPageSelection button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--Blue);
    padding: 10px 20px;
    margin: 5px;
    font-weight: bold;
    border: none;
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
}

.ListCourses .selection .coursesPageSelection button:hover {
    box-shadow: 1px 1px 5px 1px rgba(255, 255, 255, 0.1);
    background-color: rgb(31, 0, 62);
}

.ListCourses .CoursesUnic {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    color: var(--White);
    background-color: var(--Primary);
    width: 80%;
    border-radius: 6px;
    padding: 10px;
    margin: 10px;
}

.ListCourses .CoursesUnic .text {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;

}
.ListCourses .CoursesUnic .text {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
    width: 100%;
    flex-wrap: wrap;

}
.ListCourses .CoursesUnic .text .textUnic {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
    width: 250px;

}
.ListCourses .CoursesUnic .text .textUnic h5 {
    margin:5px;
}
.ListCourses .CoursesUnic .text select{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 6px;
    margin: 10px 0;
    border: 1px solid rgb(27, 0, 88);
    color: rgb(27, 0, 88);
    padding: 10px
}
.ListCourses .CoursesUnic .text .button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:flex-end;
    width: 100px;
}
.ListCourses .CoursesUnic .text .button button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    padding:8px;
    margin: 5px;
    border: none;
    font-size: 12px;
}
.ListCourses .CoursesUnic .text .button button:hover {
    background-color: var(--Blue);
    color: var(--White);
}
.ListCourses .CoursesUnic .text .button button svg {
    font-size:16px;
}

.ListCourses .link {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    width: 100%
}
.ListCourses .link a {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--White);
    background-color: var(--Primary);
    border-radius: 6px;
    padding: 10px;
    margin: 5px 5px 20px 5px;
    border: none;
    text-decoration: none;
    font-weight: 700;
}

@media(max-width: 650px) {
    .ListCourses .CoursesUnic {
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
    }

    .ListCourses .CoursesUnic .text {
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content:center;
        width: 100%;
    }
}