.IconsFormContactEAD{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:100%;
    background-color:var(--Background);
    padding: 50px 0;
}

.IconsFormContactEAD .follow{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:80%;
    background-color:var(--Primary);
    padding: 20px;
    margin: 40px;
}

.IconsFormContactEAD .follow .title{
    color: var(--White);
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
}

.IconsFormContactEAD .follow .title h1{
    color: var(--White);
    text-align: center;
}

.IconsFormContactEAD .follow .icons{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:center;
    width:80%;
    background-color:var(--Primary);
    padding: 20px;
    margin: 10px;
}

.IconsFormContactEAD .follow .icons p{
    background-color: #633294;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 50%;
    margin: 10px;
}

.IconsFormContactEAD .follow .icons p svg{
    font-size: 40px;
    color: var(--Blue);
}

.IconsFormContactEAD .map{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:100%;
    background-color:var(--Blue);
    padding: 50px 0;
    color: var(--White);
}
.IconsFormContactEAD .map .map2{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:80%;
    padding: 50px 0;
}
.IconsFormContactEAD .map .text{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width:50%;
    margin-top: -100px;
    background-color:var(--Primary);
    padding: 20px 0;
}
.IconsFormContactEAD .map .text h4{
    font-weight: 400;
    text-align: center;
}




@media(max-width:650px) {

    .IconsFormContactEAD .follow .icons p{
        background-color: #633294;
        width:60px;
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border-radius: 50%;
        margin: 10px;
    }
    
    .IconsFormContactEAD .follow .icons p svg{
        font-size: 30px;
        color: var(--Blue);
    }

    .IconsFormContactEAD .map .map2{
        width:99%;
    }

    .IconsFormContactEAD .map .text{
        width:80%;
        padding: 20px;
    }

    .IconsFormContactEAD .map .text h4{
        font-size: 14px;
    }
}