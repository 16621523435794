.ListDepoiments {
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--Primary);
    width: 100%;
}

.ListDepoiments h1 {
    margin: 40px;
}

.ListDepoiments .DepoimentUnic {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    color: var(--White);
    background-color: var(--Primary);
    width: 80%;
    border-radius: 6px;
    padding: 10px;
    margin: 10px;
}

.ListDepoiments .DepoimentUnic .text {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-between;
    width: 80%;
}
.ListDepoiments .DepoimentUnic .text h5 {
    margin:5px;
}
.ListDepoiments .DepoimentUnic .button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:flex-end;
}
.ListDepoiments .DepoimentUnic .button button {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--Primary);
    background-color: var(--White);
    border-radius: 6px;
    padding: 8px;
    margin: 5px;
    border: none;
    font-size: 12px;
}


.ListDepoiments .DepoimentUnic .button button:hover {
    background-color: var(--Blue);
    color: var(--White);
}
.ListDepoiments .DepoimentUnic .button button svg {
    font-size:16px;
}


.ListDepoiments .link a {
    display:flex;
    align-items: center;
    flex-direction: row;
    justify-content:center;
    color: var(--White);
    background-color: var(--Primary);
    border-radius: 6px;
    padding: 10px;
    margin: 5px;
    border: none;
    text-decoration: none;
    font-weight: 700;
}

@media(max-width: 650px) {
    .ListDepoiments .DepoimentUnic {
        flex-direction: column;
        width: 100%;
    }

    .ListDepoiments .DepoimentUnic .text {
        display:flex;
        align-items: center;
        flex-direction: column;
        justify-content:center;
        width: 100%;
    }
}