.DepoimentsAll  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    color: var(--Primary);
    padding: 80px 0 0 0 ;
}
.DepoimentsAll h1  {
    text-align: center;
    margin: 40px 0;
}

.DepoimentsAll  .deps {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0;
    color: rgb(241, 241, 241);
} 

.DepoimentsAll .deps .depoimentUnicAll {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 45%;
    padding: 10px;
    color: #512A79;
    background-color: rgb(241, 241, 241);
    border-radius: 15px;
    margin: 10px;
}

.DepoimentsAll .deps .depoimentUnicAll .image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin: 0px auto;
}

.DepoimentsAll .deps .depoimentUnicAll .image img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.DepoimentsAll .deps .depoimentUnicAll h4 {
    text-align: center;
    margin: 10px 10px 15px 10px;

}

.DepoimentsAll .deps .depoimentUnicAll h5 {
    text-align: center;
    font-weight: 400;
}

.DepoimentsAll .deps .depoimentUnicAll p {
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
}

@media (max-width: 900px) {
    .DepoimentsAll .deps .depoimentUnicAll {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 45%;
        padding: 10px;
        color: #512A79;
        background-color: rgb(241, 241, 241);
        border-radius: 15px;
        margin: 10px;
    }
    
    .DepoimentsAll .deps .depoimentUnicAll .image {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
        margin: 0px auto;
    }

    .DepoimentsAll .deps .depoimentUnicAll .image img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
        object-fit: cover;
    }

    @media (max-width: 800px) {

        .DepoimentsAll  .deps {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px 0;
            color: rgb(241, 241, 241);
        } 

        
        .DepoimentsAll .deps .depoimentUnicAll {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 90%;
            padding: 10px;
            color: #512A79;
            background-color: rgb(241, 241, 241);
            border-radius: 15px;
            margin: 10px;
        }
        
        .DepoimentsAll .deps .depoimentUnicAll .image {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            object-fit: cover;
            margin: 0px auto;
        }
    
        .DepoimentsAll .deps .depoimentUnicAll .image img {
            width: 60px;
            height: 60px;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}

