.MostViewedCourses {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 40px 0;
}
.MostViewedCourses .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 80%;
}
.MostViewedCourses .top h3 {
    font-weight: 400;
    color: #373737;
}
.MostViewedCourses .top a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 7px 15px;
    border: 2px solid var(--Primary);
    color: var(--Primary);
    border-radius: 20px;
    font-weight: 500;
    background-color: var(--Background);
}
.MostViewedCourses .courses {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 90%;
    flex-wrap: wrap;
    margin: 10px 0;
}
.MostViewedCourses .courses .CourseUnicDestaque {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    border-radius: 6px;
    margin: 10px;
    -webkit-box-shadow: 0px 0px 15px -1px #000000; 
    box-shadow: 0px 0px 5px -1px #000000;
}
.MostViewedCourses .courses .CourseUnicDestaque a {
    width: 100%;
    height: 80px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}
.MostViewedCourses .courses .CourseUnicDestaque .image {
    width: 100%;
    height: 80px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}
.MostViewedCourses .courses .CourseUnicDestaque .image img {
    width: 100%;
    height: 80px;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
}

.MostViewedCourses .courses .CourseUnicDestaque .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 80px;
    color: #373737;
    text-align: center;
    padding: 5px;
}
.MostViewedCourses .courses .CourseUnicDestaque .title h5 a {
   text-decoration: none;
   color: #373737;
}
.MostViewedCourses .courses .CourseUnicDestaque .title a {
   text-decoration: none;
   color: #373737;
   margin-top: 28px;
}

    .MostViewedCourses .courses .CourseUnicDestaque .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        border-radius:  0 0 6px 6px;
    }
    .MostViewedCourses .courses .CourseUnicDestaque .info .icon {
        width: 50px;
        height: 50px;
        color: var(--Primary);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .MostViewedCourses .courses .CourseUnicDestaque .info .icon svg {
        font-size: 20px;
    }
    .MostViewedCourses .courses .CourseUnicDestaque .info .text {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 5px;
    }
    .MostViewedCourses .courses .CourseUnicDestaque .info .text h5 {
        color: #373737;
        font-weight: 400;
        font-size:13px;
        text-align: left;
    }
    .MostViewedCourses .courses .CourseUnicDestaque .info .text h4 {
        color: var(--Primary);
        font-weight: 400;
        font-size:13px;
        text-align: left;
    }

    @media(max-width:550px){
        .MostViewedCourses .top {
            flex-direction: column;
            width: 90%;
        }

        .MostViewedCourses .top a {
            margin-top: 10px;
        }
    }