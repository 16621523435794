.contactForm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: var(--Primary);
}


.contactForm .title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    padding: 20px 0;
}
.contactForm .contactBlock {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}
.contactForm .contactBlock .image {
    width: 50%;
    height: 550px;
    object-fit: cover;
    background-color: var(--Background2);
    filter: opacity(0.7)
}
.contactForm .contactBlock .image img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    filter: opacity(0.9)
}
.contactForm .contactBlock .formulary {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    margin: 80px 0;
}

.contactForm .contactBlock .formulary h1{
    color: #ffffff;
    margin-top: 40px;
}

.contactForm .contactBlock .formulary p{
        text-align: left;
        color: #e2e2e2;
        font-size:14px
}

.contactForm .contactBlock .formulary form{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 20px
}

.contactForm .contactBlock .formulary form .name{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
}

.contactForm .contactBlock .formulary form .name input{
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    width: 50%;
    height: 40px;
    margin: 5px;
    background-color: var(--Background);
    color: va(--Background2);
}

.contactForm .contactBlock .formulary form .name input::placeholder {
    color: va(--Background2);
}

.contactForm .contactBlock .formulary form input{
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    width: 100%;
    height: 40px;
    margin: 5px;
    background-color: var(--Background);
    color: va(--Background2);
}
.contactForm .contactBlock .formulary form select{
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    width: 100%;
    height: 40px;
    margin: 5px;
    background-color: var(--Background);
    color: va(--Background2);
}
.contactForm .contactBlock .formulary form input::placeholder {
    color: va(--Background2);
}
.contactForm .contactBlock .formulary form textarea{
    padding: 5px 10px;
    border-radius: 6px;
    border: none;
    width: 100%;
    height: 80px;
    margin: 5px;
    background-color: var(--Background);
    color: va(--Background2);
}
.contactForm .contactBlock .formulary form button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    width: 100%;
    margin: 20px 0;
    background-color: var(--Blue);
    font-weight: 700;
    color: var(--White);
    text-decoration: none;
}

.contactForm .contactBlock .formulary form textarea::placeholder {
    color: va(--Background2);;
}

    .contactForm .contactBlock .information {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 30%;
        border-radius: 6px;
        padding: 20px;
        margin: 0px 20px;
        background-color: rgb(21, 0, 41);
    }

    .contactForm .contactBlock .information .item {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 5px 0;
    }

   .contactForm .contactBlock .information .item h3{
        color: #ffffff;
    }
    
    .contactForm .contactBlock .information .item p{
        text-align: left;
        color: #e5e5e5;
        font-size:14px
    }

    .contactForm .contactBlock .information .social {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 5px 0;
    }

    .contactForm .contactBlock .information .social h3{
        color: #ffffff;
    }
    
    .contactForm .contactBlock .information .social .icons{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
    }

    .contactForm .contactBlock .information .social .icons p {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        padding: 3px;
        color: rgb(27, 0, 54);
        margin: 3px;
        border-radius: 3px;
    }
     

    @media(max-width:650px) {

        .contactForm .contactBlock .image {
            display: none;
        }

        .contactForm .contactBlock .formulary {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 80px 0;
        }

        .contactForm .contactBlock .formulary form .name{
            flex-direction: column;
            width: 100%;
        }
        
        .contactForm .contactBlock .formulary form .name input{
            width: 98%;
        }
    }
    
    
