.dropdown-menu {
  background-color: rgb(27, 0, 54);
  width: 200px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
  z-index: 99;
}

.dropdown-menu li {
  background-color: rgb(27, 0, 54);
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #290053;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
