.Partners {
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: row;
    width: 100%;
    padding: 100px 50px;
    background-color:var(--Background);
    flex-wrap: wrap;
}
.Partners .image{
    width: 150px;
    height: 150px;
    object-fit: cover;
    -webkit-box-shadow: 0px 0px 15px -1px #000000; 
    box-shadow: 0px 0px 5px -1px #000000;
    border-radius: 6px;
    margin: 25px;

}
.Partners .image img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 6px;

}
